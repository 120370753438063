<template>
  <v-container class="pt-7">
    <v-row>
      <v-col xl="8" lg="10" class="mx-auto">
        <h1 class="fs-24 fw-700 opacity-80 mb-4 fc-vi">{{ $t('checkout') }}</h1>

        <div class="mb-4">
          <address-dialog
            :show="addDialogShow"
            @close="addressDialogClosed"
            :old-address="addressSelectedForEdit"
          />

          <h3 class="opacity-80 mb-3 fs-20 fc-vi">{{ $t('shipping_address') }}</h3>

          <div class="mb-4">
            <div
              class="position-relative mb-3"
              v-for="address in getAddresses"
              :key="address.id"
            >
              <label class="aiz-megabox d-block">
                <input
                  type="radio"
                  name="checkout_shipping"
                  v-model="selectedShippingAddressId"
                  :value="address.id"
                  :checked="address.default_shipping"
                  @change="shippingAddressSelected(address.id)"
                />
                <span class="d-flex pa-3 aiz-megabox-elem fs-13 fw-600">
                  <span class="aiz-rounded-check flex-shrink-0 mt-1"></span>
                  <span class="flex-grow-1 ps-3 opacity-80 lh-1-5 primary--text">
                    <span class="d-block primary--text">
                      {{ address.address }}, {{ address.postal_code }}
                    </span>
                    <span class="d-block">
                      {{ address.city?.name }}, {{ address.state?.name }},
                      {{ address.country.name }}
                    </span>
                    <span>{{ address.phone }}</span>
                  </span>
                </span>
              </label>
              <v-btn
                class="absolute-right-center me-3"
                color="primary"
                elevation="0"
                small
                @click="editAddress(address)"
              >
                {{ $t('change') }}
              </v-btn>
            </div>

            <v-btn
              class="border-dashed border-gray-300 primary--text fs-14"
              elevation="0"
              block
              x-large
              @click.stop="addDialogShow = true"
            >
              <i class="las la-plus"></i>
              <span>{{ $t('add_new_address') }}</span>
            </v-btn>
          </div>
        </div>

        <div class="mb-4">
          <h3 class="opacity-80 mb-3 fs-20 fc-vi">{{ $t('order_summary') }}</h3>
          <div class="mb-4">
            <v-row>
              <v-col cols="12" sm="8">
                <div
                  class="border border-gray-200 rounded px-6 py-5 grey lighten-5"
                >
                  <v-row class="">
                    <v-col cols="8" class="fw-500 opacity-80 text-primary">
                      {{ $t('sub_total') }}
                    </v-col>
                    <v-col cols="4" class="fw-700 text-red">
                      {{ format_price(getCartPrice - getCartTax, false) }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="8" class="fw-500 opacity-80 text-primary">
                      {{ $t('shipping_charge') }}
                    </v-col>
                    <v-col cols="4" class="fw-700 text-red">
                      {{
                        this.selectedDeliveryOption === 'standard'
                          ? format_price(
                              standardDeliveryCost * getCartShops.length
                            )
                          : format_price(
                              expressDeliveryCost * getCartShops.length
                            )
                      }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="8" class="fw-500 opacity-80 text-primary">
                      {{ $t('tax') }}
                    </v-col>
                    <v-col cols="4" class="fw-700 text-red">
                      {{ format_price(getCartTax, false) }}
                    </v-col>
                  </v-row>
                  <v-divider class="mt-3 mb-2"></v-divider>

                  <coupon-form
                    v-if="!is_addon_activated('multi_vendor')"
                    for-checkout
                  />

                  <v-row class="mt-0">
                    <v-col cols="8" class="fw-500 opacity-80 text-primary">
                      {{ $t('discount') }}
                    </v-col>
                    <v-col cols="4" class="fw-700 text-red">
                      {{ format_price(getTotalCouponDiscount) }}
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                  <v-row class="fs-16">
                    <v-col cols="8" class="fw-500 opacity-80 text-primary">
                      {{ $t('total_to_pay') }}
                    </v-col>
                    <v-col cols="4" class="fw-700 text-red">
                      {{ format_price(totalPrice, false) }}
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col
                v-if="$store.getters['app/banners'].checkout_page.img"
                cols="12"
                sm="4"
              >
                <banner
                  :loading="false"
                  :banner="$store.getters['app/banners'].checkout_page"
                  class="checkout-banner"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="mb-4">
          <!-- 线上、线下支付 -->
          <!-- <h3 class="opacity-80 mb-3 fs-20">{{ $t('payment_options') }}</h3>
          <v-row class="mb-3">
            <v-col cols="6" sm="4" md="3" v-for="(item, index) in paymentMethods" :key="index">
              <label class="aiz-megabox d-block">
                <input
                  type="radio"
                  name="checkout_payment_method"
                  :checked="selectedPaymentMethod && item.code === selectedPaymentMethod.code"
                  @change="paymentSelected($event, item)"
                />
                <span class="d-block pa-3 aiz-megabox-elem text-center">
                  <img :src="addResourceImage(item.img)" class="img-fluid w-100" />
                  <span class="fw-700 fs-14">{{ item.name }}</span>
                </span>
              </label>
            </v-col>

            <v-col
              cols="6"
              sm="4"
              md="3"
              v-for="offlinePaymentMethod in offlinePaymentMethods"
              :key="offlinePaymentMethod.code"
            >
              <label class="aiz-megabox d-block">
                <input
                  type="radio"
                  name="wallet_payment_method"
                  :checked="
                    selectedPaymentMethod && offlinePaymentMethod.code == selectedPaymentMethod.code
                  "
                  @change="paymentSelected($event, offlinePaymentMethod)"
                />
                <span class="d-block pa-3 aiz-megabox-elem text-center">
                  <img :src="addResourceImage(offlinePaymentMethod.img)" class="img-fluid w-100" />
                  <span class="fw-700 fs-13">
                    {{ offlinePaymentMethod.name }}
                  </span>
                </span>
              </label>
            </v-col>
          </v-row> -->

          <!-- show offline payment method's data -->
          <div
            v-if="
              selectedPaymentMethod &&
              selectedPaymentMethod.type === 'bank_payment'
            "
            class="my-3"
          >
            <h3 class="opacity-80 mb-3 fs-18 text-capitalize">
              {{ $t('account_details') }}
            </h3>

            <div class="border px-2 py-2">
              <div class="text-capitalize my-1">
                <span class="font-weight-bold">{{ $t('payment_name') }}</span>
                :
                {{ selectedPaymentMethod.name }}
              </div>
              <div class="text-capitalize my-1">
                <span class="font-weight-bold">{{ $t('payment_type') }}</span>
                :
                {{ selectedPaymentMethod.type }}
              </div>
              <div
                class="text-capitalize d-flex my-1"
                v-if="selectedPaymentMethod.content"
              >
                <span class="font-weight-bold me-1">
                  {{ $t('description') }} :
                </span>
                <span v-html="selectedPaymentMethod.content"></span>
              </div>
              <div
                class="text-capitalize"
                v-if="JSON.parse(selectedPaymentMethod.bank_info).length > 0"
              >
                <span class="font-weight-bold">{{ $t('bank_info') }}:</span>
                <div
                  class="border px-2 py-2 mt-2"
                  v-for="bankInfo in JSON.parse(
                    selectedPaymentMethod.bank_info
                  )"
                  :key="bankInfo.bank_name"
                >
                  <div>{{ $t('bank_name') }}: {{ bankInfo.bank_name }}</div>
                  <div>
                    {{ $t('account_name') }}: {{ bankInfo.account_name }}
                  </div>
                  <div>
                    {{ $t('account_number') }}: {{ bankInfo.account_number }}
                  </div>
                  <div>
                    {{ $t('routing_number') }}: {{ bankInfo.routing_number }}
                  </div>
                </div>
              </div>

              <!-- show offline payment method's inputs -->
              <div v-if="selectedPaymentMethod">
                <v-text-field
                  class="my-2"
                  :placeholder="$t('transaction_id')"
                  type="text"
                  v-model="transactionId"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>

                <v-file-input
                  accept="image/*"
                  :placeholder="$t('add_receipt')"
                  flat
                  outlined
                  prepend-icon=""
                  clearable
                  v-model="receipt"
                ></v-file-input>
              </div>
              <!-- show offline payment method's inputs -->
            </div>
          </div>

          <template v-if="generalSettings.wallet_system == 1">
            <!-- <div class="mt-4 mb-3 fs-16 fw-700">{{ $t('or') }}</div> -->
            <div
              :class="[
                'border rounded pa-4 d-flex',
                {
                  'bg-soft-primary border-primary':
                    selectedPaymentMethod &&
                    selectedPaymentMethod.type === 'wallet'
                }
              ]"
            >
              <recharge-dialog
                :show="isVisible"
                :paymentMethods="paymentMethods"
                from="/user/wallet"
                @onClose="onClose"
                @onRefresh="onRefresh"
              />

              <v-row align="center" style="justify-content: space-between">
                <!-- <v-col cols="12" sm="4">
                  <v-btn
                    color="red"
                    elevation="0"
                    class="px-7 white--text"
                    @click.stop="walletSelected()"
                  >
                    {{ $t('pay_with_wallet') }}
                  </v-btn>
                </v-col> -->

                <!-- 余额、 -->
                <v-col cols="12" sm="4" class="lh-1-5 fc-vi">
                  <div>
                    <span>{{ $t('your_wallet_balance') }} :</span>
                    <span class="fw-700 fs-15 text-red">
                      {{ format_price(currentUser.balance) }}
                    </span>
                  </div>
                  <div
                    v-if="
                      selectedPaymentMethod &&
                      selectedPaymentMethod.code == 'wallet'
                    "
                  >
                    <span>{{ $t('remaining_balance') }} :</span>
                    <span class="fw-700 fs-15">
                      {{ format_price(currentUser.balance - totalPrice) }}
                    </span>
                  </div>
                </v-col>

                <!-- 充值钱包按钮 -->
                <v-col cols="12" sm="4" class="text-sm-end">
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="px-7"
                    @click.stop="isVisible = true"
                  >
                    {{ $t('recharge_wallet') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>

        <!-- 充值条款 -->
        <div>
          {{ $t('by_clicking_proceed_i_agree_to') }}
          {{ $store.getters['app/appName'] }}'s
          <router-link
            :to="{
              name: 'CustomPage',
              params: { pageSlug: 'terms' }
            }"
            class="fc-vi fw-500"
          >
            {{ $t('terms_and_conditions') }}
          </router-link>
        </div>

        <!-- 选择付款方式后'继续付款' -->
        <div class="my-8">
          <v-btn
            elevation="0"
            color="primary"
            class=""
            x-large
            @click="proceedCheckout"
            :loading="checkoutLoading"
            :disabled="checkoutLoading"
          >
            <span class="">{{ $t('proceed') }}</span>
            <span class="border-start text-red border-gray-400 ps-5 ms-5">
              {{ format_price(totalPrice) }}
            </span>
          </v-btn>
        </div>

        <Payment ref="makePayment" />

        <FailedDialog ref="failedPayment" />

        <v-overlay :value="checkoutLoading" z-index="99999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import AddressDialog from '../components/address/AddressDialog.vue'
import RechargeDialog from '../components/wallet/RechargeDialog.vue'
import Payment from './../components/payment/Payment.vue'
import FailedDialog from './../components/payment/FailedDialog.vue'
import CouponForm from '../components/cart/CouponForm.vue'

export default {
  name: 'AizShopCheckout',

  components: {
    AddressDialog,
    RechargeDialog,
    Payment,
    FailedDialog,
    CouponForm
  },

  data() {
    return {
      checkoutLoading: false,
      selectedShippingAddressId: null,
      // selectedBillingAddressId: null,
      // 2022/9/1，先隐藏其他支付方式，所以这边默认使用钱包支付
      selectedPaymentMethod: { type: 'wallet', name: 'wallet' },
      selectedDeliveryOption: '',
      standardDeliveryCost: 0,
      expressDeliveryCost: 0,
      addDialogShow: false,
      addressSelectedForEdit: {},
      isVisible: false,
      transactionId: null,
      receipt: null
    }
  },

  computed: {
    ...mapState('wallet', ['paymentMethods']),
    ...mapGetters('app', [
      'generalSettings',
      'appUrl',
      'offlinePaymentMethods'
    ]),
    ...mapGetters('address', [
      'getAddresses',
      'getDefaultShippingAddress',
      'getDefaultBillingAddress'
    ]),
    ...mapGetters('cart', [
      'getCartPrice',
      'getTotalCouponDiscount',
      'getCartTax',
      'getCartShops',
      'getStandardTime',
      'getExpressTime',
      'getAllCouponCodes',
      'getSelectedCartIds',
      'checkShopMinOrder'
    ]),
    ...mapGetters('auth', ['currentUser']),
    totalPrice() {
      return this.selectedDeliveryOption === 'standard'
        ? this.getCartPrice -
            this.getTotalCouponDiscount +
            this.standardDeliveryCost * this.getCartShops.length
        : this.getCartPrice -
            this.getTotalCouponDiscount +
            this.expressDeliveryCost * this.getCartShops.length
    }
  },
  async created() {
    await this.fetchAddresses()
    this.selectedShippingAddressId = this.getDefaultShippingAddress.id
    // this.selectedBillingAddressId = this.getDefaultBillingAddress.id
    this.getShippingCost(this.selectedShippingAddressId)
    this.fetchRechargeMethods()
  },

  mounted() {
    if (this.$route.query.cart_payment && this.$route.query.order_code) {
      if (this.$route.query.cart_payment == 'success') {
        this.$router
          .push({
            name: 'OrderConfirmed',
            query: {
              orderCode: this.$route.query.order_code
            }
          })
          .catch(() => {})
        this.snack({ message: 'Payment successful!' })
      } else if (this.$route.query.cart_payment == 'failed') {
        this.$refs.failedPayment.open({
          orderCode: this.$route.query.order_code,
          paymentMethod: this.$route.query.payment_method
        })
      }
    }
    this.rechargeWallet(this.$route.query.wallet_payment)
  },

  methods: {
    ...mapActions('wallet', ['fetchRechargeMethods']),
    ...mapActions('cart', ['resetCoupon', 'removeMultipleFromCart']),
    ...mapActions('address', ['fetchAddresses']),
    ...mapActions('auth', ['rechargeWallet', 'deductFromWallet', 'getUser']),

    addressDialogClosed() {
      this.addressSelectedForEdit = {}
      this.addDialogShow = false
    },

    editAddress(address) {
      this.addressSelectedForEdit = address
      this.addDialogShow = true
    },

    onClose() {
      this.isVisible = false
    },

    onRefresh() {
      this.getUser()
    },

    paymentSelected(event, paymentMethod) {
      this.selectedPaymentMethod = paymentMethod
    },

    walletSelected() {
      if (this.currentUser.balance >= this.totalPrice) {
        this.selectedPaymentMethod = {
          type: 'wallet',
          name: 'wallet'
        }
      } else {
        this.snack({
          message: this.$t('insufficient_wallet_balance'),
          color: 'red'
        })
      }
    },

    shippingAddressSelected(address_id) {
      this.getShippingCost(address_id)
    },

    async getShippingCost(address_id) {
      const cartIds = this.getSelectedCartIds

      const res = await this.call_api(
        'get',
        `checkout/get-shipping-cost/${address_id}?cart_item_ids=${cartIds.join(
          ','
        )}`
      )
      // this.selectedDeliveryOption = res.data.success ? 'standard' : ''

      this.selectedDeliveryOption = 'standard'

      this.standardDeliveryCost = parseFloat(res.data.standard_delivery_cost)
      this.expressDeliveryCost = parseFloat(res.data.express_delivery_cost)
    },

    /**
     * '继续'付款
     */
    async proceedCheckout() {
      // 判断钱包余额是否充足
      if (Number(this.currentUser.balance) < Number(this.totalPrice)) {
        this.snack({
          message: this.$t('insufficient_wallet_balance'),
          color: 'red'
        })
        return
      }

      if (!this.getSelectedCartIds.length) {
        this.snack({
          message: this.$t(`please_select_a_cart_product`),
          color: 'red'
        })
        return
      }

      if (!this.getAddresses.length) {
        this.snack({
          message: this.$t('please_add_a_delivery_address'),
          color: 'red'
        })
        return
      }

      if (!this.selectedShippingAddressId) {
        this.snack({
          message: this.$t(`please_select_a_delivery_address`),
          color: 'red'
        })
        return
      }

      const formData = new FormData()
      formData.append('shipping_address_id', this.selectedShippingAddressId)
      // formData.append('billing_address_id', this.selectedBillingAddressId)
      formData.append('payment_type', this.selectedPaymentMethod.name)
      // formData.append('delivery_type', this.selectedDeliveryOption)

      const cartIds = this.getSelectedCartIds
      cartIds.forEach((item) => {
        formData.append('cart_item_ids[]', item)
      })

      const coupon_codes = this.getAllCouponCodes
      coupon_codes.forEach((couponItem) => {
        formData.append('coupon_codes[]', couponItem)
      })

      formData.append('transactionId', this.transactionId)
      formData.append('receipt', this.receipt)

      if (this.getCartPrice > 0) {
        this.checkoutLoading = true
        const res = await this.call_api(
          'post',
          'checkout/order/store',
          formData
        )
        if (res.data.success) {
          if (res.data.payment_method === 'wallet') {
            this.deductFromWallet(res.data.grand_total)
          }

          if (res.data.go_to_payment) {
            this.$refs.makePayment.pay({
              requestedFrom: '/checkout',
              paymentAmount: 0,
              paymentMethod: res.data.payment_method,
              paymentType: 'cart_payment',
              userId: this.currentUser.id,
              oderCode: res.data.order_code
            })
          } else {
            this.$router
              .push({
                name: 'OrderConfirmed',
                query: { orderCode: res.data.order_code }
              })
              .catch(() => {})
          }
          setTimeout(() => {
            this.resetCoupon()
            this.removeMultipleFromCart(this.getSelectedCartIds)
          }, 2000)
        } else {
          this.snack({
            message: res.data.message,
            color: 'red'
          })
        }
        this.checkoutLoading = false
      }
    }
  }
}
</script>
<style>
@media (min-width: 600px) {
  .checkout-banner img {
    height: 281px;
    object-fit: cover;
  }
}

.fc-vi {
  color: #C20000 !important;
}
</style>
